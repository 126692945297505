import { CircularProgress, Stack } from "@mui/joy";
import React, { useContext, useMemo } from "react";

export default function LoaderPage({ }) {
    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
                style={{ width: '100%', height: '100%' }}
            >
                {/* <img src="/eat.png" width={80} /> */}
                <img width={120} style={{ filter: 'grayscale(1)', opacity: '0.3' }} src="../logo.png" />
                <CircularProgress size="md" />
            </Stack>
        </div>
    )
}