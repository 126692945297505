import axios from 'axios';
import Cookies from 'js-cookie';


// типы оплаты:
// Банковские карты
export const TYPE_PAY_NONAL = '69E60F44-033D-CA4C-AA92-4A165CA93587';
// Оплата на сайте
export const TYPE_PAY_FOR_SITE = 'D6405965-A654-3444-8AA0-19071256B7F8';
// Наличные
export const TYPE_PAY_NAL = '3C80A070-E7A6-4F91-B1F5-7B8F1643B89D';

export const API_URL = 'https://api.tsunami-perm.ru'

// Список элементов, которые не должны показыаться
export const LIST_DELETE_ALL_MENU = ["88085561-F418-C847-A470-D0DEC154B8FD"];
// Список разделов меню, котрые лучше не показывать
const LIST_FILTER_MENU = [ "5DBD352E-D55B-8B46-BEDD-EB8801C40612", "88864743-9E00-5A4A-9F9B-3411D7CBC02B", "4A984431-757D-3A44-BA73-1EBAEC7282A1", "88085561-F418-C847-A470-D0DEC154B8FD"];
const API_MENU = 'https://api.tsunami-perm.ru/api/menu-list';
export const MAIN_GROUP = 'C9DA7ACA-9048-094A-B38F-1BE93A1F3214';
// Запрос меню 
export const TilipadGetMenu = async () => {
  try {
    const response = await axios.get(API_MENU, { timeout: 8000 })
    // console.log(response.data);
    const resp_data = response?.data?.filter(item => !LIST_FILTER_MENU.includes(item?.unpacked_data?.mgrp_ID) );
    return resp_data;
    // if (response.data.hasOwnProperty('MenuGroups')) {
    //   const menu = response.data.MenuGroups.filter(item => item.hasOwnProperty('mgrp_mgrp_ID_Parent'));
    //   response.data.MenuGroups = menu
    //   return response.data;
    // }
    // return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};



const API_PRODUCT = 'https://api.tsunami-perm.ru/api/product-list';
// Запрос элементов меню 
export const TilipadGetMenuItems = async (id = undefined) => {
  try {
    const response = id !== undefined ? await axios.get(API_PRODUCT, {params: { id: id }, timeout: 8000 }) : await axios.get(API_PRODUCT, { timeout: 8000 })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return [{}];
  }
};

const CREATE_GUEST = 'https://api.tsunami-perm.ru/api/guest-create'
// Создание счета
export const TilipadCreateGuest = async (data) => {
  try {
    // { timeout: 8000 }
    const response = await axios.post(CREATE_GUEST, data)
    if (response.data.hasOwnProperty('gest_ID')) {
      return response.data;
    }
    return {};
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return {};
  }
};


const GET_IMAGE_PRODUCT = 'https://api.tsunami-perm.ru/api/get-image'
// Создание счета
export const TilipadGetImageProduct = async (id) => {
  try {
    const response = await axios.get(GET_IMAGE_PRODUCT, { params: { id: id }, responseType: 'blob' })
    if (response.data.size != 0) {
      // Создаем URL для изображения и используем его в компоненте
      const imageUrl = URL.createObjectURL(response.data);
      return imageUrl;
    }
    else return undefined;
    
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return undefined;
  }
};


// Првоеряем есть ли артикул, и соединяем их для кнопок
export const TilipadCheckSyncArticle = (product, list_products) => {
  // 1) Проверяем есть ли у объекта артикул
  if (product?.mitm_Article) {
    const split_article = product?.mitm_Article?.split('|');
    // 2) Разбиваем артикул на элементы (<артикул>,<название>)
    if (split_article.length == 2) {
      // 3) Если есть артикл, ищем подобный элемент
      const found = list_products.find(item => {
        const item_article_split = item?.mitm_Article?.split('|');
        return item?.mitm_ID !== product?.mitm_ID && 
                item?.mitm_Article && item_article_split[0] === split_article[0];
      });
      if (found) {
        // console.log("Найден элемент:", found);
        return found;
      }
    }
  }
  return undefined;
};



const API_CREATE_INVOICE = 'https://api.tsunami-perm.ru/api/create-invoice';
// Запрос на создание оплаты
export const ServerCreateInvoice = async (data) => {
  try {
    const response = await axios.post(API_CREATE_INVOICE, data)
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return {};
  }
};



const API_CHECK_INVOICE = 'https://api.tsunami-perm.ru/api/check-pay';
// Запрос проверки платежа
export const ServerCheckInvoice = async (token) => {
  try {
    const response = await axios.get(API_CHECK_INVOICE, { params: { token: token }, timeout: 8000 })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};


const API_GET_USER = 'https://api.tsunami-perm.ru/api/auth/test-token';
// Запросить пользователя
export const GetUser = async () => {
  try {
    const u_token = Cookies.get('u_token');
    const response = await axios.get(API_GET_USER, { headers: {
      Authorization: u_token
    }, timeout: 8000 })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};



const API_LOGIN_ACCOUNT = 'https://api.tsunami-perm.ru/api/auth/login';
// Авторизация пользователя
export const LoginAccount = async (data) => {
  try {
    const response = await axios.post(API_LOGIN_ACCOUNT, data)
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};


const API_CEHCK_CODE = 'https://api.tsunami-perm.ru/api/auth/check-code';
// Проверяем код
export const CheckCode = async (data) => {
  try {
    const response = await axios.post(API_CEHCK_CODE, data)
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};




const API_CHANGE_ACCOUNT = 'https://api.tsunami-perm.ru/api/auth/change-acc';
// Изменяем данные аккаунта
export const ChangeAccount = async (data) => {
  try {
    const u_token = Cookies.get('u_token');
    const response = await axios.post(API_CHANGE_ACCOUNT, data, { headers: {
      Authorization: u_token
    }, timeout: 8000 })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};



const API_LOGOUT_ACCOUNT = 'https://api.tsunami-perm.ru/api/auth/logout';
// Выход из аккаунта
export const LogoutAccount = async () => {
  try {
    const u_token = Cookies.get('u_token');
    const response = await axios.get(API_LOGOUT_ACCOUNT, { headers: {
      Authorization: u_token
    }, timeout: 8000 })
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};



const API_REGISTRATION_ACCOUNT = 'https://api.tsunami-perm.ru/api/auth/signup';
// Регистрация аккаунта
export const RegistrationAccount = async (data) => {
  try {
    const response = await axios.post(API_REGISTRATION_ACCOUNT, data)
    return response.data;
  } catch (error) {
    console.log(error);
    if (error.code === 'ECONNABORTED') {
      console.error('Запрос превысил лимит ожидания.');
    } 
    if (error.code === 'ERR_NETWORK') {
      console.error('Ошибка сети.');
    } else {
      console.error('Произошла ошибка:', error.message);
    }
    return error.response.data;
  }
};



// const GET_IMAGE_MENU_PRODUCT = 'https://api.tsunami-perm.ru/api/get-menu-image'
// // Создание счета
// export const TilipadGetImageMenuProduct = async (id) => {
//   try {
//     const response = await axios.get(GET_IMAGE_MENU_PRODUCT, { params: { id: id }, responseType: 'blob' })
//     if (response.data.size != 0) {
//       // Создаем URL для изображения и используем его в компоненте
//       const imageUrl = URL.createObjectURL(response.data);
//       console.log('IMAGE');
//       console.log(imageUrl);
//       return imageUrl;
//     }
//     else return undefined;
    
//   } catch (error) {
//     console.log(error);
//     if (error.code === 'ECONNABORTED') {
//       console.error('Запрос превысил лимит ожидания.');
//     } 
//     if (error.code === 'ERR_NETWORK') {
//       console.error('Ошибка сети.');
//     } else {
//       console.error('Произошла ошибка:', error.message);
//     }
//     return undefined;
//   }
// };