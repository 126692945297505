import './App.css';
import React, { Suspense, lazy } from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssVarsProvider, extendTheme } from '@mui/joy';

// import MainPage from './pages/MainPage';
// import MenuPage from './pages/MenuPage';
// import Privacy from './pages/Privacy';
// import NotFound from './pages/NotFound';
// import Layout from './pages/Layout';
// import WebLayout from './pages/webapp/WebLayout';
// import WebMainPage from './pages/webapp/WebMainPage';
// import WebMenuPage from './pages/webapp/WebMenuPage';
// import WebContactPage from './pages/webapp/WebContactPage';

import { AnimatePresence } from 'framer-motion';
import { LayoutProvider } from './components/context';
import LoaderPage from './components/UI/LoaderPage/LoaderPage';


const MainPage = lazy(() => import('./pages/MainPage'));
const MenuPage = lazy(() => import('./pages/MenuPage'));
const Privacy = lazy(() => import('./pages/Privacy'));
const NotFound = lazy(() => import('./pages/NotFound'));
const Layout = lazy(() => import('./pages/Layout'));
const WebLayout = lazy(() => import('./pages/webapp/WebLayout'));
const WebMainPage = lazy(() => import('./pages/webapp/WebMainPage'));
const WebMenuPage = lazy(() => import('./pages/webapp/WebMenuPage'));
const WebContactPage = lazy(() => import('./pages/webapp/WebContactPage'));



function App() {

  const theme = extendTheme({
    colorSchemes: {
      light: {
        palette: {
          primary: {
            50: '#e3f4ff',
            100: '#d1edff', // Цвет если soft вариант
            200: '#b8e3ff', // Цвет при наведении на кнопку если soft вариант
            300: '#2a95db', // Цвет бортиков если вариант outlined
            400: '#2178DD', // -
            500: '#009DFF', // Цвет фона для всех вариантов
            600: '#008ce3', // Цвет фона при наведении если solid вариант
            700: '#007ecc', // Цвет фона для всех вариантов (при нажатии)
            800: '#2F4968', // -
            900: '#2F3C4C', // -
          },
          danger: {
            50: "#fde3e4",
            100: "#facccd",
            200: "#f49999",
            300: "#e0666b",
            400: "#cc3340",
            500: "#c30013",
            600: "#b10011",
            700: "#9e000e",
            800: "#8b000b",
            900: "#780008"
          },
          neutral: {
            500: '#242424', // Цвет фона для всех вариантов
            600: '#2e2e2e', // Цвет фона при наведении если solid вариант
            700: '#1f1f1f', // Цвет фона для всех вариантов (при нажатии)
          },
        },
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
        
        extrasmall: 300,
        small: 500,
        medium: 800,
        large: 1100,
      },
    },
    fontFamily: {
      body: 'Nunito',
      display: 'Nunito',
      fallback: 'Nunito'
    },
    components: {
      JoyButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            transition: '.2s',
            borderRadius: theme.vars.radius.lg
          })
        }
      },
    },
  });

  return (
    <CssVarsProvider
      theme={theme}
    >
      <BrowserRouter>
        <Suspense fallback={<LoaderPage/>}>
          <AnimatePresence>
            <LayoutProvider>
              <Routes>
                <Route path='/' className='hello'
                  element={
                    <Layout>
                      <MainPage/>
                    </Layout>
                  }
                />
                <Route path="/menu/:slugMenu" 
                  element={
                    <Layout>
                      <MenuPage/>
                    </Layout>
                  }
                />
                <Route path="/privacy/" 
                  element={
                    <Layout>
                      <Privacy/>
                    </Layout>
                  }
                />


                <Route path='/webapp/' 
                  element={
                    <WebLayout>
                      <WebMainPage />
                    </WebLayout>
                  }
                />
                <Route path="/webapp/menu/:slugMenu" 
                  element={
                    <WebLayout>
                      <WebMenuPage/>
                    </WebLayout>
                  }
                />
                <Route path="/webapp/contacts/" 
                  element={
                    <WebLayout>
                      <WebContactPage />
                    </WebLayout>
                  }
                />

                {/* Маршрут 404 должен быть последним */}
                <Route path="*" 
                  element={
                    <Layout>
                      <NotFound />
                    </Layout>
                  } 
                />
              </Routes>
            </LayoutProvider>
          </AnimatePresence>
        </Suspense>
      </BrowserRouter>
    </CssVarsProvider>
  );
}

export default App;